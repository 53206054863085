.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  .navLink {
    display: block;
    font-weight: 500;
    color: #333;
    padding: 8px 16px;
    margin-bottom: 4px;
    cursor: pointer;

    &.active {
      color: #007bff;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 48px; /* Height of navbar */
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }

  h6 {
    font-weight: normal;
    margin-top: 32px;
    text-transform: uppercase;
    font-size: 14px;
    color: #999999;
  }
}

.navbarBrand {
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  text-align: center;

  img {
    height: 48px;
  }
}

.left {
  flex: 1;
  padding-left: 32px !important;
  margin: 0 !important;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  color: #ffffff;
  width: 100%;

  .message {
    flex: 1;
    text-align: right;
    margin-top: 3px;
  }
  .avatar {
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block;

    img {
      height: 32px;
      margin-top: -1px;
      border-radius: 100px;
      border: 1px #999999 solid;
    }
  }
}

.content {
  padding-bottom: 32px;
}

.heading {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px #e7e7e7 solid;
}

.logoutLink {
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
}
